import './App.css';
import React, { Component } from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import Web3 from "web3";
import detectEthereumProvider from '@metamask/detect-provider';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK, { CoinbaseWalletProvider } from "@coinbase/wallet-sdk";
import Profile from './component/profile';
import user from './assets/user.png';
import mM from './assets/MetaMask.png';
import Axios from 'axios';
import pic from './assets/pic.jpg';
import secretGif from './assets/secret_images.gif';
import $ from "jquery";
import arrow from './assets/up-arrrows.png';

// const wordsCount = require('words-count').default;  
var cors = require('cors');

const website = () => {
	window.open("https://treepoets.com/");
}

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;


let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let accountETH;
let mintAmountETH = 1;
let valueOfNFTsETH = 0;
let totalSupplyNFTETH;
let maxMintNFTs;
let onlyLeft;
let now;
let myTotalWL;
let tokenIDArray = [];
let tokenIDBool;
let x;
let successMessage;
let walletExist;
let points_table_array;
let votedAddresses = [];
let match = 0;
let eligible = 0;
let max_per_wallet;
let cost;
let tokenOfOwnerByIndex = [];
let max_per_walletETH;
let costETH;
let lastTokenMinted;
let myTokenIDs = [];
let selectedNFTID;
let polygonActivate = 0;
let ethActive = 1;

var date_ob;
var day;
var month;
var year;

var date;

var hours;
var minutes;
var seconds;

var dateTime;

const ABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "ID",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_timeGap",
				"type": "uint256"
			}
		],
		"name": "setTimeGap",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "lastMintedNFTID",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "mintedCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "mintedTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "myMintedCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "myMintedNFTs",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "timeGap",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const address = "0x55844a47e467e3B35D994b113Da8b4dad2A36394";

const ABIETH = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_cost",
				"type": "uint256"
			}
		],
		"name": "setMintRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	}
];

const addressETH = "0xD53EEAF08dAebA65e99e5E925D2381410FF11276";

let contract;
let contractETH;
let wMintAmount = 1;
let totalWhitelistMinted;
let walletBalance;
let wStatus;
let publicSale;
let publicSaleETH;
let myNFTBalance;
let gasLimit = 285000;
let whitelistStatus;
let owner;
let ownerETH;
let tokenID;
let tokenURI;
let imageURI;
let plus;
let minus;
let points = 0;
let db_points;
let writersWallet;
var text;
let alreadySubmitted = false;
let initialScreen = 1;
let clickedWalletBtn = 0;
let publicMintMsg = "Public Mint";
let lastMintedNFTID;
let mintedTime = [];
let myTokenIDsWithTime = [];
let timeGap = 0;
let activePolygon;

function getWalletAddress() {

	// Get the input text value
	writersWallet = document.getElementById("writerWallet1").value;
}

function words() {

	// Get the input text value
	text = document
		.getElementById("w3review").value;

	// Initialize the word counter
	var numWords = 0;

	// Loop through the text
	// and count spaces in it
	for (var i = 0; i < text.length; i++) {
		var currentCharacter = text[i];

		// Check if the character is a space
		if (currentCharacter == " ") {
			numWords += 1;
		}
	}

	// Add 1 to make the count equal to
	// the number of words
	// (count of words = count of spaces + 1)
	numWords += 1;

	// Display it as output
	document.getElementById("show")
		.innerHTML = numWords;
}

function countWords(self) {
	try {

		var spaces = self.value.match("/\S+/g");
		var words = spaces ? spaces.length : 0;

		document.getElementById("words-counter").innerHTML = words + " words";

	} catch (err) {
		console.log(err);

	}

}

$(document).ready(() => {
	$('#photo').change(function () {
		const file = this.files[0];
		console.log(file);
		if (file) {
			let reader = new FileReader();
			reader.onload = function (event) {
				console.log(event.target.result);
				$('#imgPreview').attr('src', event.target.result);
			}
			reader.readAsDataURL(file);
		}
	});
});


class App extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "",
		presaleValue: "",
		maxmint: '',
		stateNow: '',
		totalWhitelistSupply: '',
		myTotalWLNFTs: '',
		accountBalance: '',
		wlStatus: '',
		wlEvent: false,
		myNFTWallet: '',
		priceDisplay: '',
		tokenIDDisplay: '',
		tokenURIDisplay: '',
		imageURIDisplay: '',
		notice_board_Array: [],
		state_img_url: '',
		_plus: '',
		_minus: '',
		_points: 0,
		_db_points: '',
		_walletList: '',
		_text: '',
		_successMessage: '',
		_walletExist: '',
		_alreadySubmitted: false,
		points_table_Array: [],
		_votedAddresses: [],
		_eligible: 0,
		_max_per_wallet: '',
		_cost: '',
		_tokenOfOwnerByIndex: [],
		_lastTokenMinted: '',
		_initialScreen: 0,
		_costETH: '',
		myNFTWalletETH: '',
		_max_per_walletETH: '',
		totalSupplyETH: '',
		_ownerETH: '',
		_myTokenIDs: [],
		_selectedNFTID: '',
		walletAddressETH: '',
		_polygonActivate: 0,
		_ethActive: 1,
		_day: '',
		_year: '',
		_date: '',
		_hours: '',
		_minutes: '',
		_seconds: '',
		_dateTime: '',
		_clickedWalletBtn: 0,
		_publicMintMsg: 'Public Mint',
		_lastMintedNFTID: '',
		_timeGap: '',
		_mintedTime: [],
		_myTokenIDsWithTime: [],
		_timeGap: 0,
		_NFTID: ''
	}

	async componentDidMount() {

		try {

			let NFTID = localStorage?.getItem('SelectedNFTID');
			this.setState({ _NFTID: NFTID });
			await new Promise(resolve => setTimeout(resolve, 250));

			console.log("SelectedNFTID :" + this.state._NFTID);

			activePolygon = localStorage?.getItem('polygonActivate');
			this.setState({ _polygonActivate: activePolygon });







		} catch (err) {
			console.log(err);
		}

	}

	closeWindow = async event => {
		event.preventDefault();

		successMessage = 0;
		this.setState({ _successMessage: successMessage });
		console.log("status" + this.state._successMessage);


	}

	wordsCount = async event => {
		event.preventDefault();
		words();
	}

	plus = async event => {
		event.preventDefault();
		points = points + 1;
		this.setState({ _points: points });



		Axios.post('https://alpha-bunny-nft.herokuapp.com/pointsup', {

			list_wallet: writersWallet

		}).then(() => {
			alert("Successful Insert :" + this.state._walletList);
		});
		console.log("Data Inserted");



	}

	minus = async event => {
		event.preventDefault();
		points = points - 1;
		this.setState({ _points: points });

		console.log("Writer" + writersWallet);
		console.log("WriterState" + writersWallet);


		Axios.post('https://alpha-bunny-nft.herokuapp.com/pointsdown', {

			list_wallet: writersWallet

		}).then(() => {
			alert("Successful Insert");
		});
		console.log("Data Inserted");
	}

	walletConnect = async event => {
		event.preventDefault();

		clickedWalletBtn = 1;

		this.setState({ _clickedWalletBtn: clickedWalletBtn });

		const providerOptions = {

			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "811915bee3744bd38afcf17ecac0f9a6" // required
				}
			},
			coinbasewallet: {
				package: CoinbaseWalletSDK, // Required
				options: {
					appName: "Tree Poet", // Required
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
					rpc: "", // Optional if `infuraId` is provided; otherwise it's required
					chainId: 137, // Optional. It defaults to 1 if not provided
					darkMode: true // Optional. Use dark theme, defaults to false
				}
			}

		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});

		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect" && web3Modal.cachedProvider != "coinbasewallet") {
						console.log("Function - walletConnect : " + web3Modal.cachedProvider);
						const chainId = 137;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],

												chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/

												chainName: 'Polygon Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Polygon Mainnet', decimals: 18, symbol: 'MATIC' },
												rpcUrls: ['https://polygon-rpc.com/'],

											},
										],
									});
								}
							}
						}

						try {

							let previousWalletAddress = localStorage?.getItem('walletAddress');

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							cost = await contract.methods.publicSaleCost().call();
							this.setState({ _cost: cost });
							console.log("cost :" + cost);

							for (let y = 0; y < this.state.myNFTWallet; y++) {
								tokenOfOwnerByIndex[y] = await contract.methods.tokenOfOwnerByIndex(account, y).call();
								this.setState({ _tokenOfOwnerByIndex: tokenOfOwnerByIndex })

							}

							owner = await contract.methods.owner().call();
							this.setState({ _owner: owner });
							console.log("Owner" + owner);

							for (let y = 0; y < this.state._myTokenIDs.length; y++) {

								mintedTime[this.state._myTokenIDs[y]] = await contract.methods.mintedTime(this.state._myTokenIDs[y]).call();
								this.setState({ _mintedTime: mintedTime });
								console.log("mintedTime" + mintedTime);
							}
							await new Promise(resolve => setTimeout(resolve, 250));

							timeGap = Date().now * 1 - mintedTime[this.state._selectedNFTID] * 1;
							console.log("timeGap : " + timeGap);

						} catch (err) {
							console.log("err: " + err);
						}
					} else if (web3Modal.cachedProvider == "coinbasewallet") {

						const chainId = 137;


						if (CoinbaseWalletProvider.networkVersion !== chainId) {
							try {
								await CoinbaseWalletProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await CoinbaseWalletProvider.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Polygon Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Polygon Mainnet', decimals: 18, symbol: 'MATIC' },
												rpcUrls: ['https://polygon-rpc.com/'],
											},
										],
									});
								}
							}
						}

						try {

							let previousWalletAddress = localStorage?.getItem('walletAddress');

							max_per_wallet = await contract.methods.max_per_wallet().call();
							this.setState({ _max_per_wallet: max_per_wallet });
							console.log("max_per_wallet:" + max_per_wallet);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							cost = await contract.methods.publicSaleCost().call();
							this.setState({ _cost: cost });
							console.log("cost :" + cost);

							for (let y = 0; y < this.state.myNFTWallet; y++) {
								tokenOfOwnerByIndex[y] = await contract.methods.tokenOfOwnerByIndex(account, y).call();
								this.setState({ _tokenOfOwnerByIndex: tokenOfOwnerByIndex })

							}

							owner = await contract.methods.owner().call();
							this.setState({ _owner: owner });
							console.log("Owner" + owner);


							for (let y = 0; y < this.state._myTokenIDs.length; y++) {

								mintedTime[y] = await contract.methods.mintedTime(this.state._myTokenIDs[y]).call();
								this.setState({ _mintedTime: mintedTime });
								console.log("mintedTime" + mintedTime);
							}

							timeGap = Date().now - mintedTime[this.state._selectedNFTID];
							console.log("timeGap : " + timeGap);

						} catch (err) {
							console.log("err: " + err);

						}
					} else if (web3Modal.cachedProvider == "walletconnect") {

						const chainId = 1;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await CoinbaseWalletProvider.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Polygon Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Polygon Mainnet', decimals: 18, symbol: 'MATIC' },
												rpcUrls: ['https://polygon-rpc.com/'],
											},
										],
									});
								}
							}
						}

						try {

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							max_per_wallet = await contract.methods.max_per_wallet().call();
							this.setState({ _max_per_wallet: max_per_wallet });
							console.log("max_per_wallet:" + max_per_wallet);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							cost = await contract.methods.publicSaleCost().call();
							this.setState({ _cost: cost });
							console.log("cost :" + cost);

							for (let y = 0; y < this.state.myNFTWallet; y++) {
								tokenOfOwnerByIndex[y] = await contract.methods.tokenOfOwnerByIndex(account, y).call();
								this.setState({ _tokenOfOwnerByIndex: tokenOfOwnerByIndex })

							}

							owner = await contract.methods.owner().call();
							this.setState({ _owner: owner });
							console.log("Owner" + owner);


							for (let y = 0; y < this.state._myTokenIDs.length; y++) {

								mintedTime[y] = await contract.methods.mintedTime(this.state._myTokenIDs[y]).call();
								this.setState({ _mintedTime: mintedTime });
								console.log("mintedTime" + mintedTime);
							}

							timeGap = Date().now - mintedTime[this.state._selectedNFTID];
							console.log("timeGap : " + timeGap);

						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//






				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}






		}

	}

	async createDonationRequest(ID) {
		await new Promise(resolve => setTimeout(resolve, 250));

		selectedNFTID = ID;
		console.log("ID:" + ID);

		this.setState({ _selectedNFTID: ID });
		localStorage.setItem('poetryTokenSelected', ID);
		console.log("selectedNFTID : " + this.state._selectedNFTID);

		localStorage.setItem('SelectedNFTID', ID);
		localStorage.setItem('walletAddress', account);
		localStorage.setItem('polygonActivate', 1);

		initialScreen = 0;
		this.setState({ _initialScreen: initialScreen });
		console.log("initialScreen : " + this.state._initialScreen);

		await new Promise(resolve => setTimeout(resolve, 250));

		polygonActivate = 1;
		this.setState({ _polygonActivate: polygonActivate });
		console.log("polygonActive: " + polygonActivate);

		ethActive = 0;
		this.setState({ _ethActive: ethActive });
		console.log("ethActive: " + ethActive);

		date_ob = new Date();
		day = ("0" + date_ob.getDate()).slice(-2);

		month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
		year = date_ob.getFullYear();

		date = year + "-" + month + "-" + day;
		console.log(date);

		hours = date_ob.getHours();
		minutes = date_ob.getMinutes();
		seconds = date_ob.getSeconds();

		this.setState({ _day: day, _year: year, _date: date, _hours: hours, _minutes: minutes, _seconds: seconds });


		await new Promise(resolve => setTimeout(resolve, 250));

		/*	localStorage.setItem('poetryTokenSelected', ID);
			localStorage.removeItem('poetryTokenSelected',ID);
	
			console.log("poetryTokenSelecteda-3:" + localStorage?.getItem('poetryTokenSelected'));
	*/

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
				}
			},
			coinbasewallet: {
				package: CoinbaseWalletSDK, // Required
				options: {
					appName: "Tree Poet", // Required
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
					rpc: "", // Optional if `infuraId` is provided; otherwise it's required
					chainId: 1, // Optional. It defaults to 1 if not provided
					darkMode: true // Optional. Use dark theme, defaults to false
				}
			}

		};
		/*
				const web3Modal = new Web3Modal({
					network: "mainnet", // optional
					cacheProvider: true, // optional
					providerOptions // required
				});
		
				// disconnect wallet
				web3Modal.clearCachedProvider();
				window.location.reload();
		*/
	}

	walletConnectEthereum = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
				}
			},
			coinbasewallet: {
				package: CoinbaseWalletSDK, // Required
				options: {
					appName: "Tree Poet", // Required
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
					rpc: "", // Optional if `infuraId` is provided; otherwise it's required
					chainId: 1, // Optional. It defaults to 1 if not provided
					darkMode: true // Optional. Use dark theme, defaults to false
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);

		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddressETH: account });

			contractETH = new web3.eth.Contract(ABIETH, addressETH);
			console.log("contract :" + contractETH);

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect" && web3Modal.cachedProvider != "coinbasewallet") {



						const chainId = 1;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/

											},
										],
									});
								}
							}
						}

						try {

							totalSupplyNFTETH = await contractETH.methods.totalSupply().call();
							this.setState({ totalSupplyETH: totalSupplyNFTETH });
							console.log("Total SupplyETH:" + totalSupplyNFTETH);

							/*max_per_walletETH = await contractETH.methods.max_per_wallet().call();
							this.setState({ _max_per_walletETH: max_per_walletETH });
							console.log("max_per_walletETH:" + max_per_walletETH);*/

							publicSaleETH = await contractETH.methods.balanceOf(account).call();
							this.setState({ myNFTWalletETH: publicSaleETH });

							/*costETH = await contractETH.methods.publicSaleCost().call();
							this.setState({ _costETH: costETH });
							console.log("costETH :" + costETH);*/

							ownerETH = await contractETH.methods.owner().call();
							this.setState({ _ownerETH: ownerETH });
							console.log("OwnerETH" + ownerETH);

							for (let y = 0; y < this.state.myNFTWalletETH; y++) {
								myTokenIDs[y] = await contractETH.methods.tokenOfOwnerByIndex(account, y).call();
								this.setState({ _myTokenIDs: myTokenIDs })

							}

							console.log("myTokenIDs : " + myTokenIDs[0]);
							console.log("myTokenIDs : " + this.state._myTokenIDs);

						} catch (err) {
							console.log("err: " + err);

						}
					} else if (web3Modal.cachedProvider == "coinbasewallet") {

						const chainId = 1;

						if (CoinbaseWalletProvider.networkVersion !== chainId) {
							try {
								await CoinbaseWalletProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await CoinbaseWalletProvider.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://mainnet.infura.io/v3/'],
											},
										],
									});
								}
							}
						}

						try {

							totalSupplyNFTETH = await contractETH.methods.totalSupply().call();
							this.setState({ totalSupplyETH: totalSupplyNFTETH });
							console.log("Total SupplyETH:" + totalSupplyNFTETH);

							/*max_per_walletETH = await contractETH.methods.max_per_wallet().call();
							this.setState({ _max_per_walletETH: max_per_walletETH });
							console.log("max_per_walletETH:" + max_per_walletETH);*/

							publicSaleETH = await contractETH.methods.balanceOf(account).call();
							this.setState({ myNFTWalletETH: publicSaleETH });

							/*costETH = await contractETH.methods.publicSaleCost().call();
							this.setState({ _costETH: costETH });
							console.log("costETH :" + costETH);*/

							ownerETH = await contractETH.methods.owner().call();
							this.setState({ _ownerETH: ownerETH });
							console.log("OwnerETH" + ownerETH);

							for (let y = 0; y < this.state.myNFTWalletETH; y++) {
								myTokenIDs[y] = await contractETH.methods.tokenOfOwnerByIndex(account, y).call();
								this.setState({ _myTokenIDs: myTokenIDs })

							}

							console.log("myTokenIDs : " + myTokenIDs[0]);
							console.log("myTokenIDs : " + this.state._myTokenIDs);

						} catch (err) {
							console.log("err: " + err);

						}
					} else if (web3Modal.cachedProvider == "wallconnect") {

						const chainId = 1;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await CoinbaseWalletProvider.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://mainnet.infura.io/v3/'],
											},
										],
									});
								}
							}
						}


						try {

							totalSupplyNFTETH = await contractETH.methods.totalSupply().call();
							this.setState({ totalSupplyETH: totalSupplyNFTETH });
							console.log("Total SupplyETH:" + totalSupplyNFTETH);

							/*max_per_walletETH = await contractETH.methods.max_per_wallet().call();
							this.setState({ _max_per_walletETH: max_per_walletETH });
							console.log("max_per_walletETH:" + max_per_walletETH);*/

							publicSaleETH = await contractETH.methods.balanceOf(account).call();
							this.setState({ myNFTWalletETH: publicSaleETH });

							/*costETH = await contractETH.methods.publicSaleCost().call();
							this.setState({ _costETH: costETH });
							console.log("costETH :" + costETH);*/

							ownerETH = await contractETH.methods.owner().call();
							this.setState({ _ownerETH: ownerETH });
							console.log("OwnerETH" + ownerETH);

							for (let y = 0; y < this.state.myNFTWalletETH; y++) {
								myTokenIDs[y] = await contractETH.methods.tokenOfOwnerByIndex(account, y).call();
								this.setState({ _myTokenIDs: myTokenIDs })

							}

							console.log("myTokenIDs : " + myTokenIDs[0]);
							console.log("myTokenIDs : " + this.state._myTokenIDs);

						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//






				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}


			initialScreen = 0;
			this.setState({ _initialScreen: initialScreen });
			console.log("initialScreen : " + initialScreen);



		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
				}
			},
			coinbasewallet: {
				package: CoinbaseWalletSDK, // Required
				options: {
					appName: "Tree Poet", // Required
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
					rpc: "", // Optional if `infuraId` is provided; otherwise it's required
					chainId: 1, // Optional. It defaults to 1 if not provided
					darkMode: true // Optional. Use dark theme, defaults to false
				}
			}

		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		//window.location.reload();



	}

	closeWindow = async event => {
		event.preventDefault();

		clickedWalletBtn = 0;
		this.setState({ _clickedWalletBtn: clickedWalletBtn })
		console.log("clickedWalletBtn" + this.state._clickedWalletBtn);

	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);


		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {

					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);
					console.log("Function - onSubmit2 : ");

					let NFTID = localStorage?.getItem('SelectedNFTID');
					this.setState({ _NFTID: NFTID });

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(NFTID).send({ gasLimit: 685000, from: account, value: this.state.totalValue * 0 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);




				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);

				}


			} else {

				try {

					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					let NFTID = localStorage?.getItem('SelectedNFTID');
					this.setState({ _NFTID: NFTID });

					totalSupplyNFT = await contract.methods.totalSupply().call();
					this.setState({ totalSupply: totalSupplyNFT });
					console.log("Total Supply:" + totalSupplyNFT);

					await new Promise(resolve => setTimeout(resolve, 750));


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(NFTID).send({ gasLimit: 685000, from: account, value: this.state.totalValue * 0 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);


				} catch (err) {

					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);

				}

			}
		} catch (err) {

			console.log(err);

		}

		await new Promise(resolve => setTimeout(resolve, 750));



	}

	render() {

		return (

			<div class="allWrap">
				<div class="light">
					<div class="headerPicAndTitle">
						<div class="nb">Tree Poetry NFT Creator</div>
						<div class="connect2">
							<div class="connect2-2">

								{this.state.walletAddressETH === '' ?
									(<form onSubmit={this.walletConnectEthereum}>

										{this.state._ethActive === 1 ?
											(<button class="wallet2">Choose Poet</button>) :
											(<button class="wallet2Dis" disabled>Choose Poet</button>)}


									</form>) : (<form onSubmit={this.walletDisconnect}>

										{this.state._ethActive === 1 ?

											(<button class="wallet2" >
												{this.state.walletAddressETH.slice(0, 3) + "..." + this.state.walletAddressETH.slice(39, 42)}</button>)
											: (<button class="wallet2Dis" disabled>
												{this.state.walletAddressETH.slice(0, 3) + "..." + this.state.walletAddressETH.slice(39, 42)}</button>)}
									</form>)}
							</div>
						</div>
					</div>
					<form class="form" action="https://poetree-nft.herokuapp.com/nftCreate" method="post" enctype="multipart/form-data">

						<div>
							<div class="formCon">Choose Tree Poet NFT</div>
							<div class="formCon2">Tree Poet holder receives claim priority and donation rewards of NFT mint.</div>
							<div class="tokenIds" >

								{this.state._myTokenIDs.map((element) => {

									var ID = element * 1;

									if (element == 0) {
										var url = "https://treepoets.com/images/ETH/" + ID + ".jpg";
										console.log("url :" + url);


									} else {

										var url = "https://treepoets.com/images/ETH/" + ID + ".jpg";

									}



									return (
										<div class="ownNFT">
											<div class="flip-card">
												<div class="flip-card-inner">
													<div class="flip-card-front">


														<img class="images_show" src={url} /></div>

													<div class="flip-card-back">
														<div class="elementName">{element}</div>
														<button class="stake" onClick={() => this.createDonationRequest(element)}>SELECT</button>
													</div>

												</div>
											</div></div>);



								})}


							</div>

							<button class="mintMore" onClick={website}>Mint Tree Poets NFTs</button>
						</div>

						<div class="picAndCon">
							<div class="imageBar">
								<img id="imgPreview" src={pic} alt="Preview Here" />
								<div class="upload">
									<div id="block_container">
										<div class="fileBtn">
											<input class="choosebtn" type="file" name="file" accept="image/png"
												id="photo" required="true" />

										</div>

									</div>
								</div>
							</div>


							<div class="contentBar">
								<div class="contentH">Metadata</div>

								<p></p>

								{this.state._polygonActivate !== 0 ?
									(<div>
										<div class="textField">
											Creator: Tree Poet #{this.state._NFTID}
										</div>

										<p></p>

									</div>) : null}

								<p></p>

								<div class="textField">
									<div><input hidden type="text" class="comment" name="creator" value={this.state.walletAddress} placeholder="" /></div>
								</div>
								<p></p>

								<div class="textField">
									<div></div>
									<div >
										<input hidden type="text" class="comment" value={this.state.totalSupply} name="tokenID" placeholder="" />
										<input hidden type="text" class="comment" value={this.state._NFTID} name="creatorTokenID" placeholder="" />
									</div>
								</div>
								<p></p>

								<div class="textField">
									Charity / Nonprofit:
									<div><input type="text" class="comment" name="yourName" placeholder="" /></div>
								</div>

								<p></p>
								<div class="textField">
									Donation Request US$:
									<div><input type="number" class="comment" name="donation" placeholder="" /></div>
								</div>
							</div>
						</div>

						<div class="descriptionMain">
							<div class="description">Description</div>

							<textarea class="descriptionCon" name="textArea">
								This NFT was created by Tree Poets as a gift for charitable donation and may include copyright transfer of displayed poetry and/or visual art as specified in the metadata properties. If the metadata property, IP Transferred, is Poetry, Art or Poetry & Art then the copyright ownership indicated is transferred to token holder and if value is None then the copyrights are retained by the original creator.
							</textarea>

							<div class="agreeBtn">
								<input class="tic" type="radio" id="agreeBtn" name="agreeBtn" value="None" />
								<div class="agree">I certify I am original creator and/or content is of public domain and I DO NOT transfer any content copyright.</div>

							</div>

							<p></p>

							<div class="agreeBtn2">
								<div class="agreeBtn4">
									<div class="agree">I certify I am original creator and Do transfer copyright ownership of</div>
								</div>

								<div class="agreeBtn3">
									<div class="agreeBtn">
										<input class="tic" type="radio" id="agreeBtn" name="agreeBtn" value="Poetry" />
										<div class="agree">Poetry</div>
									</div>
									<div class="agreeBtn">
										<input class="tic" type="radio" id="agreeBtn" name="agreeBtn" value="Visual Art" />
										<div class="agree">Visual Art</div>
									</div>
									<div class="agreeBtn">
										<input class="tic" type="radio" id="agreeBtn" name="agreeBtn" value="Poetry & Art" />
										<div class="agree">Poetry & Art </div>
									</div>
								</div>

							</div>

							<div class="con2Main">
								<div class="con2">
									NFT will be held in the Tree Poetry Vault until transferred for successful donation but if found to be in violation
									of copyright or terms then the NFT will be burned.

								</div>

								{this.state.walletAddress !== '' ?
									(<button class="approve" type="submit">Submit</button>) :
									(<button class="approveDis" disabled>Submit</button>)
								}

							</div>
						</div>
					</form>
					{this.state.walletAddress === '' ?
						(<form onSubmit={this.walletConnect}>

							{this.state._polygonActivate === 0 ?
								(<button class="wallet2Dis" disabled>Mint NFT</button>) :
								(<button class="wallet2">Mint NFT</button>)}

						</form>) : (<form onSubmit={this.walletDisconnect}><button class="wallet2" >
							{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}
					<p></p>
					<p></p>

				</div>
				{this.state._initialScreen === 1 ?
					(<div class="formMain1" >
						<div class="formMain">

							<div class="formCon">Choose Tree Poet NFT</div>
							<div class="formCon2">Tree Poet holder receives claim priority and donation rewards of NFT mint.</div>
							<div class="tokenIds" >

								{this.state._myTokenIDs.map((element) => {

									var ID = element * 1;

									if (element == 0) {
										var url = "https://treepoets.com/images/ETH/" + ID + ".jpg";
										console.log("url :" + url);


									} else {

										var url = "https://treepoets.com/images/ETH/" + ID + ".jpg";

									}



									return (
										<div class="ownNFT">
											<div class="flip-card">
												<div class="flip-card-inner">
													<div class="flip-card-front">


														<img class="images_show" src={url} /></div>

													<div class="flip-card-back">
														<div class="elementName">{element}</div>
														<button class="stake" onClick={() => this.createDonationRequest(element)}>SELECT</button>
													</div>

												</div>
											</div></div>);



								})}


							</div>

							<button class="mintMore" onClick={website}>Mint Tree Poets NFTs</button>

						</div>

					</div>) : null
				}


				{this.state.walletAddress !== "" && this.state._clickedWalletBtn > 0 ?
					(<div class="formMain1" >
						<div class="formMain2">

							<div class="formCon">Mint Section</div>
							<p class="formCon2">Mint an NFT to claim rights and create donation gifts that earn rewards.</p>

							<form onSubmit={this.onSubmit2}><button class="mintBtn">MINT HERE</button></form>

							{this.state.statusError ? (
								<div class="errorMessage">
									<div >Sorry, something went wrong please try again later</div>
								</div>)
								: null}

							{this.state.statusLoading ? (
								<div class="loadingContainer">
									<div>
										<div class="loadingText">Minting Digital Collectible Donation Gift</div>
									</div>
								</div>)
								: null}

							{this.state.success ? (

								<div>
									<div class="arrowAndCon">
										<div class="successfully">MINTING SUCCESSFUL!</div>
										<img class="arrow" onClick={this.closeWindow} src={arrow} />

									</div>
									<div class="instructions">Now you can go and fill your information and submit</div>

								</div>
							)
								: null}

						</div>

					</div>) : null
				}


			</div>)
	}

}

export default App;